.site-nav {
    background: #fff;
    position: relative;
    z-index: 100;
    border-bottom: 2px solid @brand-lighter;
    margin-bottom: @grid-gutter-width / 2;
    margin-top: 0;
    position: sticky;
    top: 0;

    @media (min-width: @screen-sm-min) {
        margin-bottom: @grid-gutter-width;
    }

    > .container {
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: @screen-sm-min) {
            padding-left: (@grid-gutter-width / 2);
            padding-right: (@grid-gutter-width / 2);
        }

        .row {
            margin-left: -15px;
            margin-right: -15px;

            @media (min-width: @screen-sm-min) {
                margin-left: -(@grid-gutter-width / 2);
                margin-right: -(@grid-gutter-width / 2);
            }

            .col {
                padding-left: 15px;
                padding-right: 15px;

                @media (min-width: @screen-sm-min) {
                    padding-left: (@grid-gutter-width / 2);
                    padding-right: (@grid-gutter-width / 2);
                }
            }
        }
    }

    .logo-wrap {
        margin: 0 !important;
        order: 0;
        padding-right: 0 !important;

        @media (min-width: @screen-sm-min) {
            max-width: 175px;
            flex-basis: 175px;
        }

        .logo {
            padding: 15px 0;
            display: inline-block;

            @media (max-width: @screen-sm-max) {
                padding: 20px 0;
            }

            img {
                max-height: 60px;
                max-width: 95px;
                position: relative;
                z-index: 1;

                @media (min-width: @screen-sm-min) {
                    max-height: 80px;
                    max-width: 115px;
                }
            }
        }
    }

    .pages-wrap {
        padding-left: 0 !important;

        @media (max-width: @screen-sm-max) {
            display: none;
        }

        ul.menu-items {
            list-style: none;
            padding: 0;
            margin: 0;
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: stretch;

            li {
                display: flex;
                position: relative;

                @media (max-width: @screen-md-max) {
                    flex: 0;
                }

                &.active > a  {
                    text-decoration: underline;
                }

                a {
                    font-weight: bold;
                    color: @text-color;
                    text-decoration: none;

                    &:hover, &:focus {
                        color: @link-color;
                    }
                }

                & + li {
                    margin-left: 25px;
                    padding-left: 25px;

                    &::before {
                        content: "";
                        width: 2px;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background: @brand-lighter;
                        display: block;
                    }
                }
            }
        }
    }

    .actions-nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;

        @media (min-width: @screen-md-min) {
            flex-grow: 0;
        }

        @media (max-width: 550px) {
            position: static;
        }

        > .dropdown {
            margin: 10px 0 10px 5px;

            @media (max-width: 550px) {
                position: static;
            }

            > button,
            button#cart-dropdown {
                padding: 0px 10px;
                margin: 0;
                border: 0;
                background: none;
                white-space: wrap;
                text-align: center;
                max-width: 100px;

                &:focus {
                    outline: 1px dotted @gray;
                }

                @media (min-width: @screen-sm-min) {
                    min-width: 60px;
                }
            }

            .dropdown-button-icon {
                height: 40px;
                display: block;
                font-size: 18px;
                line-height: 40px;
            }

            .dropdown-button-text {
                font-size: 9px;
                text-transform: uppercase;
                margin-bottom: 5px;
                margin-top: 3px;
                letter-spacing: -0.5px;
                font-weight: bold;

                @media (min-width: @screen-sm-min) {
                    letter-spacing: initial;
                }
            }
        }

        .dropdown-menu {
            padding: 10px;
            right: 0;
            left: auto;

            @media (max-width: 550px) {
                left: 10px;
                right: 10px;
                margin-top: -15px;
            }

            li {
                width: 100%;
                margin: 0 !important;
            }

            a {
                margin: 0 auto;
                padding: 7px 0;
                text-align: center;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    background: transparent;
                    color: @link-color;
                }
            }

            a.btn {
                &:hover,
                &:focus {
                    background: #000;
                    color: #FFF;
                }
            }

            .divider {
                display: block;
                height: 1px;
                background: @border-color;
                margin: 10px 0 !important;
            }
        }
    }

    .basket-wrap {
        .dropdown-menu {
            padding: 15px;
            max-height: 500px;
            overflow-x: hidden;
            overflow-y: auto;

            @media (min-width: @screen-sm-min) {
                white-space: nowrap;
            }

            @media (max-width: @screen-sm) {
                table.table > tfoot > tr > th {
                    border-top: 0;
                }
            }

            &:before, &:after {
                left: auto;
                right: 12px;
            }

            &:after {
                margin-left: 0;
                margin-right: -1px;
            }

            td {
                font-size: 1.3rem;

                &.v-middle {
                    vertical-align: middle;
                }

                .btn-remove {
                    padding: 0;
                    background: transparent;
                    margin-top: -4px;
                }
            }
        }

        .btn-view-cart {
            padding: 10px 15px;
            text-transform: uppercase;
            i {
                margin-left: 5px;
            }
        }

        .empty-cart {
            font-size: 1.6rem;
            display: block;
            padding: 5px;
            i {
                margin-right: 5px;
                font-size: 1.8rem;
            }
        }
    }

    .search-wrap {
        @media (max-width: @screen-sm-max) {
            display: none;
        }

        .dropdown-button-icon {
            background: @brand-primary;
            border-radius: 50%;
        }

        .dropdown-menu {
            min-width: 250px;
            max-width: 500px;
            width: 100%;
            padding: 5px 5px;

            .search-container {
                position: relative;
            }

            .form-control {
                padding-right: 40px;
                border: 0;
                box-shadow: none;
            }

            .search-submit-btn {
                position: absolute;
                right: 0;
                top: 0;
                background: none;
                padding: 0;
                color: @text-color;
                border: 0;
                text-align: center;
                width: 40px;
                height: 44px;
                line-height: 44px;

                &:hover, &:focus {
                    color: @link-color;
                }

                &:focus {
                    outline: 1px dotted @gray;
                }
            }
        }
    }

    .language-wrap {
        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }

    .nav-toggler {
        margin: 10px 0 10px 5px;
        padding: 0 0 0 10px;
        background: transparent;
        color: @text-color;

        &:hover, &:focus {
            background: transparent;

            .hamburger,
            .hamburger::before,
            .hamburger::after {
                background: @mobile-nav-toggler-color;
            }
        }

        .icon-container {
            margin: 0 auto;
            width: 40px;
            height: 40px;
            float: none;
            background: @brand-primary;
            border-radius: 50%;
        }

        .nav-toggler-text {
            font-size: 9px;
            text-transform: uppercase;
            margin-bottom: 5px;
            margin-top: 3px;
            letter-spacing: -0.5px;
            font-weight: bold;

            @media (min-width: @screen-sm-min) {
                letter-spacing: initial;
            }
        }

        .hamburger,
        .hamburger::before,
        .hamburger::after {
            background: @mobile-nav-toggler-color;
            left: 9px;
            top: 19px;
            width: 22px;
            transition: all .4s;
        }

        .hamburger::before {
            top: -6px;
            left: 0;
        }

        .hamburger::after {
            top: 6px;
            left: 0;
        }

        body.offcanvas-open & {
            .hamburger {
                background: transparent;

                &::before,
                &::after {
                    background: @mobile-nav-toggler-color;
                    top: 0;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.offcanvas-nav {
    background: @mobile-nav-bg;

    .offcanvas-search {
        background: transparent;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);

        .offcanvas-search-input {
            background: rgba(255,255,255,0.1);
            border: 1px solid rgba(255,255,255,0.1);
            color: #fff;

            &::-webkit-input-placeholder {
                color: rgba(255,255,255,0.4);
            }

            &::-moz-placeholder {
                color: rgba(255,255,255,0.4);
            }

            &:-ms-input-placeholder {
                color: rgba(255,255,255,0.4);
            }

            &:-moz-placeholder {
                color: rgba(255,255,255,0.4);
            }
        }

        .offcanvas-search-submit {
            color: #fff;
            width: 30px;
            height: 34px;
            right: 0;
            top: 0;
            text-align: center;
        }
    }

    .offcanvas-language {
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(0,0,0,0.1);

        .language-menu {
            display: flex;
            flex-flow: row wrap;

            a {
                flex: 1 1 0%;
                text-align: center;
                display: block;
                padding: 15px 0;
                text-transform: uppercase;
                color: rgba(255,255,255,0.75);
            }
        }
    }

    ul ul ul li > ul li a {
        padding-left: 60px;
    }

    li {
        background: transparent;

        &.level-0 {
            background: lighten(@mobile-nav-bg, 4%);
        }

        &.level-1 {
            background: lighten(@mobile-nav-bg, 7%);

            > .toggle-icon {
                background: transparent;

                &:after {
                    background: rgba(0,0,0,0.1);
                }
            }
        }

        &.level-2 {
            background: lighten(@mobile-nav-bg, 10%);

            > .toggle-icon {
                background: transparent;

                &:after {
                    background: rgba(0,0,0,0.1);
                }
            }
        }

        &.level-3 {
            background: @mobile-nav-link-level-3-bg;
        }

        &.current > a{
            color: #fff;
            text-decoration: underline;
            font-weight: bold;
            background-color: transparent;
        }

        a {
            color: #fff;
        }

        .toggle-icon {
            z-index: 2;

            &:before {
                color: #fff;
            }

            &:after {
                background: rgba(0,0,0,0.1);
            }
        }
    }
}
