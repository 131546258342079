.product-name {
    .author {
        font-weight: normal;
        font-style: italic;
        margin-bottom: .66em;
        display: block;
        font-size: 65%;
    }
}

.product-sku {
    margin: 1.5em 0;
}

.app-download-badges {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    img {
        height: 60px;
        width: auto;
    }
}

.custom-file-download {
    text-align: center;

    a {
        .btn();
        .btn-lg();
        text-transform: initial;
        background-color: @brand-lighter;

        &:hover,
        &:focus {
            background-color: #000;

            i,
            .file-title {
                color: #fff;
            }
        }
    }

    i {
        color: @link-color;
        margin-right: 3px;
    }

    .file-title {
        font-weight: 500;
        color: @text-color;
    }
}

.product-video-thumbnails {
    margin-top: @grid-gutter-width/2;
    padding-top: @grid-gutter-width/2;
    border-top: 1px solid @border-color;
    margin-bottom: @grid-gutter-width/2;
    display: flex;
    flex-flow: row wrap;

    .video-thumbnail {
        position: relative;
        flex-basis: 90px;
        margin: 0 @grid-gutter-width/4 @grid-gutter-width/4 0;
        background: #000;
        display: flex;

        a {
            display: flex;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        .video-thumbnail-icon {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            background: rgba(0,0,0,0.2);
            color: #fff;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            pointer-events: none;
        }
    }
}

.video-modal {
    .modal-dialog {
        margin-top: 55px !important;
    }
    .modal-content {
        background: #000;
        border: none;
    }

    button.close {
        position: absolute;
        bottom: 100%;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
    }

    .modal-close-icon {
        &::before,
        &::after {
            content: "";
            width: 24px;
            height: 3px;
            background: #fff;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: 0% 0%;
        }

        &::before {
            transform: rotate(45deg) translate(-50%, -50%);
        }

        &::after {
            transform: rotate(-45deg) translate(-50%, -50%);
        }
    }
}

.product-short-description {
    font-size: 18px;
}
