
@import './simplelightbox.less';

body {
    &.admin-tools-visible {
        padding-top: 50px;

        .site-nav {
            margin-top: 0;
        }

        .offcanvas-nav {
            top: 50px;
        }
    }
}

.container,
.container-fluid {
    width: 100% !important;

    @media (min-width: @screen-md-min) {
        padding-left: 75px;
        padding-right: 75px;
    }

    @media (min-width: @screen-lg-min) {
        padding-left: 175px;
        padding-right: 175px;
    }
}

.navbar-admin-tools .container-fluid {
    padding-left: (@grid-gutter-width / 2) !important;
    padding-right: (@grid-gutter-width / 2) !important;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    font-weight: bold;
}

.btn-primary {
    color: @brand-dark;

    &:hover, &:focus, &:active {
        background: #000;
        border-color: #000;
        color: #fff;
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -(@grid-gutter-width / 2);
    margin-right: -(@grid-gutter-width / 2);

    &.align-items-center {
        align-items: center;
    }

    .col {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-left: @grid-gutter-width / 2;
        padding-right: @grid-gutter-width / 2;
        flex-basis: 0;
        max-width: 100%;
        flex-grow: 1;
    }
}

.content-wrap {
    transition: transform .4s;

    body.offcanvas-open & {
        @media (max-width: @screen-sm-max) {
            transform: translate3d(-@mobile-nav-width, 0, 0);
        }
    }
}

.page-header {
    margin-top: 5px;
}

.xt-ph-cell {
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    min-height: 1px;
}

.title-bar {
    padding: 10px 0;
    margin-top: 0;
    margin-bottom: 30px;

    &:before {
        display: none;
    }

    &.light {
        border: 0;

        h2 {
            font-size: @font-size-h2;
        }
    }
}

.page-with-categories {
    .col.category-sidebar {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 320px;
        max-width: 320px;
        padding-left: @grid-gutter-width / 2;
        padding-right: @grid-gutter-width;
        border-right: 1px solid #eee;

        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }

    .col.detail-col {
        flex-grow: 1;
        flex-shrink: 1;
        padding-left: @grid-gutter-width;
        padding-right: @grid-gutter-width / 2;
        min-width: 0;

        @media (max-width: @screen-sm-max) {
            padding-left: @grid-gutter-width / 2;
        }

        .owl-stage {
            min-width: 100%;
            display: flex;

            .owl-item.active {
                flex-grow: 1;
                flex-shrink: 1;
                margin-right: 0 !important;

                .product-card.product-box {
                    min-width: 100%;
                }
            }
        }
    }
}

.sidebar-block {
    padding: 0 0 (@grid-gutter-width / 2) 0;
    margin-bottom: @grid-gutter-width / 4;
}

.category-sidebar .sidebar-block .sidebar-block-title,
.category-sidebar .sidebar-block h2,
.category-sidebar .sidebar-block .title {
    border: none;
    text-transform: uppercase;
    margin: 0px 0px 15px 0px;
    font-size: @font-size-large;
    color: @gray;
}

.category-sidebar .category-menu ul li a {
    border-color: @gray-lighter;
    padding: 10px;

    &:hover, &:focus {
        text-decoration: underline;
        background: none;
        color: @link-color;
        opacity: 1;
    }
}

.category-sidebar .category-menu ul ul li {
    padding-left: 20px;

    a {
        padding: 10px;
        opacity: 0.9;
    }
}

.category-sidebar .category-menu ul ul ul li a {
    padding: 10px;
    opacity: 0.7;
}

.category-sidebar .category-menu ul li.current > a {
    font-weight: bold;
    color: @link-hover-color;
    position: relative;
    text-decoration: underline;
    opacity: 1;
}

.category-sidebar .category-menu ul li.is-parent {
    > a {
        padding-right: 36px;
    }

    &.open {
        ul {
            display: none;
        }

        > ul {
            display: block;
        }
    }
}

.category-sidebar .category-menu ul li .menu-toggler {
    background: none;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    top: 5px;
    line-height: 28px;
}

.category-sidebar .category-menu ul ul li .menu-toggler {
    transform: scale(0.85);
    top: 3px;
}

.sidebar-filters .block-title {
    color: @text-muted;
}

.sidebar-filters .mobile-filter-toggle a.toggle-btn.collapsed {
    background: @gray-lighter;
}

.category-sidebar .category-menu ul li .menu-toggler:hover,
.category-sidebar .category-menu ul li .menu-toggler:focus {
    background: @brand-primary;
}

.products-wrap .product-list-actions {
    border-bottom: 0 !important;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: (@grid-gutter-width / 2);
        right: (@grid-gutter-width / 2);
        bottom: 0;
        height: 1px;
        border-bottom: 1px dotted @border-color;
    }
}

.sidebar-filters .collapsible-content {
    overflow: hidden;
    transition: height .25s;
}

.category-filter-display-more-wrap {
    display: none;
}

.sidebar-filters .filters {
    padding: 0 10px;
}

.product-list-view {
    margin-left: -10px;
    margin-right: -10px;

    .single-product {
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: @screen-md-min) and (max-width: 1050px) {
            width: 50%;

            &:nth-child(3n+1) {
                clear: none;
            }

            &:nth-child(2n+1) {
                clear: both;
            }
        }

        .prices {
            .row {
                margin-left: -10px;
                margin-right: -10px;
            }

            .col-sm-6 {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

.product-card {
    .detail-wrap {
        padding-bottom: 5px;
    }

    .render-on-hover {
        background: #fff;
    }
}

.product-image {
    max-width: 390px;

    .labels {
        left: auto;
    }
}

.product-basic-details {
    max-width: 500px;
}

.product-attributes {
    .dl-horizontal {
        dt {
            overflow: unset;
        }
    }
}

.product-files {
    a {
        color: #005eb8;
    }
}

.section-navigation .sidebar-list-item {
    &.active, &:hover, &:focus {
        color: @brand-dark;

        i {
            color: @brand-primary;
        }
    }

    i {
        width: auto;
        margin-right: 4px;
    }
}

.panel {
    .panel-title {
        border-bottom: 2px solid @border-color;
        padding: 10px 0;
    }

    .panel-heading .panel-title a {
        color: @link-color;
        text-transform: none;
        font-size: 16px;
        font-weight: normal;

        &:hover, &:focus {
            color: @link-hover-color;
        }
    }
}

#xt-ph-homepage_carousel {
    .owl-carousel {
        margin-bottom: @grid-gutter-width * 1.5;
        overflow: hidden;

        &:hover {
            .owl-nav {
                .owl-prev i {
                    left: (@grid-gutter-width / 2);
                }

                .owl-next i {
                    right: (@grid-gutter-width / 2);
                }
            }
        }
    }

    .carousel-item {
        position: relative;

        img {
            position: relative;
            z-index: 1;
        }

        .carousel-captions {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 30px;
            z-index: 2;
            color: #fff !important;
            text-decoration: none !important;

            @media (min-width: @screen-sm-min) {
                left: 50px;
                right: 50px;
                bottom: 40px;
            }

            .caption-title {
                font-weight: bold;
                background: rgba(0,0,0,0.3);
                padding: 3px 7px;
                display: inline-block;

                @media (min-width: @screen-sm-min) {
                    margin-bottom: 5px;
                    font-size: @font-size-large;
                }
            }

            .caption-text {
                background: rgba(0,0,0,0.3);
                padding: 3px 7px;
                display: inline-block;

                @media (max-width: @screen-xs-max) {
                    font-size: @font-size-small;
                }
            }
        }
    }

    .owl-nav {
        i {
            font-size: 40px;
            background: rgba(0,0,0,0.2);
            color: #fff;
            line-height: 1;
            padding: 5px (@grid-gutter-width / 2);
            transition: all .2s;

            &:hover {
                background: @brand-primary;
                color: #000;
            }
        }

        .owl-prev i {
            left: -50px;
        }

        .owl-next i {
            right: -50px;
        }
    }

    .owl-dot {
        border-color: @brand-primary;
        margin: 0 4px 10px 4px;
        vertical-align: middle;
        transition: all .15s;

        &.active {
            background: @brand-primary;
            transform: scale(1.05);
        }
    }
}

.customer-dashboard {
    padding: 0 !important;

    .section-navigation,
    .section-content {
        width: 100% !important;
    }

    .section-content {
        padding: (@grid-gutter-width / 2);

        fieldset {
            padding: 10px 15px;
            background: #f6f6f6;
            margin-bottom: 15px;

            legend {
                padding-top: 40px;
            }
        }
    }

    .section-navigation {
        .sidebar-list {
            border: solid @border-color;
            border-width: 1px 0;
            padding: 5px 0;

            .sidebar-list-item {
                border: 0;
                margin: 2px;
                font-size: 1.3rem;

                @media (min-width: @screen-sm-min) {
                    display: inline-block;
                    font-size: 1.5rem;
                }

                &:hover, &:focus {
                    i {
                        color: @link-color;
                    }
                }

                &.active {
                    font-weight: bold;
                    background: @brand-lighter;

                    i {
                        color: @text-color;
                    }
                }
            }
        }
    }
}

.bootstrap-select .filter-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-grid-wrap {
    position: relative;
}

#category-product-loading-indicator {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 75px;
    text-align: center;
    z-index: -2000;
    background: rgba(255,255,255,0.75);
    transition: all .2s;

    &.loading {
        z-index: 500;
        opacity: 1;
        visibility: visible;
    }
}
