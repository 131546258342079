.sl-wrapper .sl-navigation button {
    position: fixed;
    top: 50%;
    margin-top: -22px;
    height: 64px;
    width: 22px;
    line-height: 44px;
    text-align: center;
    display: block;
    z-index: 10060;
    font-family: @font-primary, monospace;
    color: @page-bg;
    background: @brand-primary;

    @media (max-width: 50em) {
        display: none;
    }
}

.sl-wrapper .sl-navigation button.sl-next {
    right: 5px;
    font-size: 2rem;
}

.sl-wrapper .sl-navigation button.sl-prev {
    left: 5px;
    font-size: 2rem;
}

.sl-wrapper .sl-navigation button:focus {
    outline: none;
}

@media (min-width: 35.5em) {
    .sl-wrapper .sl-navigation button {
        width: 44px;
    }

    .sl-wrapper .sl-navigation button.sl-next {
        right: 10px;
        font-size: 3rem;
    }

    .sl-wrapper .sl-navigation button.sl-prev {
        left: 10px;
        font-size: 3rem;
    }
}

@media (min-width: 50em) {
    .sl-wrapper .sl-navigation button {
        width: 44px;
    }

    .sl-wrapper .sl-navigation button.sl-next {
        right: 20px;
        font-size: 3rem;
    }

    .sl-wrapper .sl-navigation button.sl-prev {
        left: 20px;
        font-size: 3rem;
    }
}
