.product-card {
    text-align: center;
    position: relative;
    background-color: #fff;

    .actions{
        width: 100%;
    }

    .render-on-hover {
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
        border-color: transparent;
        position: absolute;
        display: none;
        top: -10px;
        left: 0;
        right: 0;
    }
    &:hover {
        z-index: 4;
        .actions {
            display: block;
        }
    }
    .product-price-div {
        h2 {
            font-size: 2.0rem;
        }
    }
    .detail-wrap {
        position: relative;
        .name {
            font-size: 2rem;
        }
        .lead {
            font-size: 16px;
        }
    }

    &:hover, &:focus {
        .overlay {
            @media (min-width: @screen-sm-min) {
                display: block;
            }
        }

        .user-actions {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s ease-in-out 0s, opacity 300ms ease-in-out 100ms;
        }
    }

    .product-card-comparison {
        float:left;
        position:relative;
    }

    .product-card-wishlist {
        float:left;
        position:relative;
    }

    &.category-view, &.search-view {
        margin-bottom: 40px;
    }

    .actions {
        display: none;
        position:absolute;
        background-color: #fff;
        padding: 15px;
        .product-name {
            display: none;
        }

        .form-group {
            span {
                font-weight: bold;
            }
            &.amount {
                .sales-unit {
                    padding: 5px;
                }
                .form-control {
                    text-align: right;
                    padding: 0;
                }
            }
        }
    }
    .overlay {
        z-index: 2;
        display: none;
        position: absolute;
        top: 40px;
        left: 15px;
        right: 15px;
        text-align: center;

        .btn-preview, .btn-quick-add {
            width: 120px;
            background: fade(@brand-dark, 90%);
            color: #fff;
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: bold;
            font-family: @font-primary;
            i {
                margin-right: 3px;
                font-size: 1.4rem;
                position: relative;
            }
            &:hover, &:focus {
                background: lighten(@brand-dark, 7%);
                color: #fff;
            }
        }
        .btn-quick-add {
            margin-top: 10px;
            background: @brand-primary;
            &:hover, &:focus {
                background: lighten(@brand-primary, 7%);
                color: #fff;
            }
        }
    }

    a {
        display: block;
        color: @text-color;
        &:hover, &:focus {
            color: #000;
            text-decoration: none;
        }
    }

    .labels {
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 5px;
        transition: all .15s;

        .label-sale,
        .label-new {
            display: inline-block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: @brand-primary;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            font-size: 1.2rem;
            font-family: @font-headline;
            transition: all .15s;
            text-transform: uppercase;
            padding: 0;
        }
        .label-sale {
            background: @label-sale-background;
            color: @label-sale-color;
        }
        .label-new {
            background: @label-new-background;
            color: @label-new-color;
        }
    }

    .image-wrap {
        position: relative;
        padding: 15px 15px 0px 15px;

        .image {
            background-position: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;

            &:before {
                content: "";
                display: block;
                position: relative;
                padding-top: 100%;
            }
        }
    }

    .user-actions {
        display: block;
        z-index: 100;
        position: absolute;
        right: 10px;
        bottom: 10px;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s ease-in-out 600ms, opacity 300ms ease-in-out 300ms;

        .action {
            float: left;
            .btn {
                margin-right: 2px;
                .text {
                    display: none;
                }
            }
        }
    }

    .user-list-actions {
        display: none;
    }

    .detail-wrap {
        min-height: 60px;
        padding: 15px 10px 0px 10px;
        font-size: 1.1rem;

        div.name {
            font-size: 1.5rem;
            padding-bottom: 5px;
        }

        p {
            margin: 0 0 0 0;
        }
    }

    .price-line {
        color: @text-color;
        font-size: 1.5rem;

        strong {
            font-weight: normal;
            font-size: 1.5rem;
        }
    }

    .btn {
        margin-top: 5px;
        font-size: 1.1rem;
        font-weight: bold;
    }
}

.owl-item {

    .product-card {
        .actions {
            top:0;
            position: absolute;
            z-index: 100;
            background-color:#fff;
            font-size: 12px;
            margin-top: 0;
            .product-name {
                font-size: 14px;
                font-weight:bold;
                margin-bottom: 5px;
                display: block;
            }
            .dropdown-toggle {
                font-size: 12px;
                padding: 3px 6px;
            }
            .form-group {
                margin:0;
            }
            .product-price-div {
                h2 {
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                }
            }
            form.add-to-basket {
                margin-bottom: 0px;
            }
            .add-to-cart-button {
                padding: 5px;
                
                @media (max-width: @screen-md-max) {
                    padding: 5px 2px;
                    font-size: 85%;
                }
            }
            .btn-preview {
                padding: 5px;
                font-size: 9px;
                margin-top: 5px;
            }
            .form-group.amount {
                label {
                    display: none;
                }
                .input-group {
                    .sales-unit {
                        font-size: 12px;
                    }
                    input {
                        padding: 0 0 0 5px;
                    }
                }
            }
        }
    }
}
