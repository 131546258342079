.form-group {
    position: relative;

    &.required,
    &.required-field {
        label.control-label:after {
            content: "*";
            color: @brand-danger;
            font-weight: bold;
            margin-left: 3px;
        }
    }
}

.custom-checkbox {
    margin-bottom: 10px;

    &.required.has-error {
        input[type="checkbox"] + label::before {
            border: 1px solid @brand-danger;
        }
    }

    input[type="checkbox"] {
        .sr-only();

        &:focus {
            & + label::before {
                background-color: fade(@brand-primary, 15%);
                border-color: fade(@brand-primary, 70%);
            }
        }

        & + label {
            position: relative;
            cursor: pointer;
            font-weight: normal;
            margin: 0;
            // Disable text select on label
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover {
                &::before {
                    background-color: fade(@brand-primary, 15%);
                    border-color: fade(@brand-primary, 70%);
                }
            }

            &::before {
                content: "";
                display: inline-block;
                background: #fff;
                width: 17px;
                height: 17px;
                border: 1px solid darken(@border-color, 10%);
                vertical-align: top;
                margin-right: 7px;
                border-radius: 2px;
                .transition(all 0.1s);
            }

            &::after {
                opacity: 0;
                content: "\f00c";
                font-family: FontAwesome;
                display: block;
                color: #fff;
                position: absolute;
                left: 2px;
                top: -1px;
                font-size: 1.4rem;
                .scale(0.4);
                .transition(all 0.25s cubic-bezier(0.75,0.1,0.1,0.5))
            }
        }

        &:checked {
            &:focus {
                & + label::before {
                    outline: 1px solid #000;
                }
            }

            & + label {
                &::before {
                    background: @brand-primary;
                    border-color: @brand-primary !important;
                }

                &::after {
                    opacity: 1;
                    .scale(1);
                }
            }
        }
    }
}

.custom-radio {
    margin-bottom: 10px;

    &.required.has-error {
        input[type="radio"] + label::before {
            border: 1px solid @brand-danger;
        }
    }

    input[type="radio"] {
        .sr-only();

        &:focus {
            &+label::before {
                background-color: fade(@brand-primary, 15%);
                border-color: fade(@brand-primary, 70%);
            }
        }

        & + label {
            position: relative;
            cursor: pointer;
            font-weight: normal;
            margin: 0;
            // Disable text select on label
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover {
                &::before {
                    background-color: fade(@brand-primary, 15%);
                    border-color: fade(@brand-primary, 70%);
                }
            }

            &::before {
                content: "";
                display: inline-block;
                background: #fff;
                width: 17px;
                height: 17px;
                border: 1px solid darken(@border-color, 10%);
                vertical-align: top;
                margin-right: 7px;
                border-radius: 50%;
                .transition(all 0.1s);
            }

            &::after {
                opacity: 0;
                content: "";
                display: block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: @brand-primary;
                position: absolute;
                left: 4px;
                top: 4px;
                .scale(0.4);
                .transition(all 0.25s cubic-bezier(0.75,0.1,0.1,0.5))
            }
        }

        &:checked {
            &:focus {
                & + label::before {
                    outline: 1px solid #000;
                }
            }

            & + label {
                &::before {
                    border-color: @brand-primary !important;
                    background: #fff;
                }

                &::after {
                    opacity: 1;
                    .scale(1);
                }
            }
        }
    }
}
