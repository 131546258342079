footer {
    margin-top: 50px;
    padding: 75px 0;
    color: @text-color;
    border-top: 1px solid @border-color;

    a {
        color: @link-color;
    }

    h2 {
        border-color: rgba(255,255,255,0.1);
    }

    .container {
        position: relative;

        .footer-logo {
            flex-grow: 0;

            img {
                max-width: 100px;
            }
        }
    }
}
