html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    color: @text-color;

    a {
        color: @text-color;
    }
    &.admin-tools-visible {
        .site-nav {
            margin-top: 50px;
        }
        .alert {
            margin-top: 52px;
        }
    }
}

.content-wrap {
    position: relative;
}

#main {
    //overflow: auto;  // removed due catalog hover
    z-index: 1;

}

.footer {
    z-index:1;
    position: relative;
    clear: both;
    padding-top: 30px;
}

.page-header {
    border: 0;
}

.btn {
    text-transform: uppercase;
    margin-left:2px;
}

.bootstrap-select {
    .btn {
        text-transform: none;
    }
}

.panel, .well {
    hr {
        border-color: @border-color;
    }
}

.scroll-top-btn {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background: rgba(0,0,0,0.3);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;

    &.visible {
        visibility: visible;
        opacity: 1;
        z-index: 1000;
    }
}

.title-bar {
    width: 100%;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 25px;

    &.light {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 6px;
            right: 0;
            bottom: 1px;
            left: 0;
            color: @title-bar-light-color;
            z-index: 0;
        }
        h1, h2, h3, h4 {
            position: relative;
            display: inline-block;
            z-index: 1;
            margin: 0;
            color: @title-bar-light-title-color;
            vertical-align: middle;
            font-size: 16px;
            padding-right: 7px;
        }
    }

    &.dark {
        padding: 10px 15px;
        background: @title-bar-dark-background;
        color: @title-bar-dark-color;
        h1, h2, h3, h4 {
            margin: 0;
            color: @title-bar-dark-title-color;
            background: @title-bar-dark-title-background;
        }
    }
}

.frontpage-carousel {
    margin-bottom: 50px;
}

section.image-banner {
    background: @brand-primary;
    padding: 70px 0px;
    text-align: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        margin: 0 40px;
        text-transform: uppercase;
        color: #fff;
        text-align: left;

        @media (min-width: @screen-md-min) {
            font-size: 4rem;
        }
    }
}

.breadcrumb {
    padding: 0;
    margin-top: 0px;
    margin-bottom: 20px;
    list-style: none;
    color: @text-muted;
    font-size: @font-size-small;
    background: none;

    > li {
        display: inline-block;

        a {
            color: @text-muted;
        }

        + li:before {
            content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
            padding: 0 2px;
        }
    }
}

.panel {
    box-shadow: none;
    border: 0;
    &.panel-white-bg {
        background: #fff;
        border: 3px solid @brand-lighter;
    }

    .panel-title {
        font-size: @font-size-h3;
        border-bottom: 3px solid @brand-primary;
        padding: 7px;
    }
}

.message-wrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    max-width: 300px;
}

.table.table-prices {
    width: 50%;
    tr {
        td {
            white-space: nowrap;
            border: none;
            .original-price {
                font-size: 13px;
                text-decoration: line-through;
            }
            .discounted-price {
                font-size: 17px;
            }
        }
    }
}

.discount-color {
    color: @discount-color;
}

.open {
    .dropdown-menu {
        animation: fadeIn 0.2s;
    }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.flash {
    z-index: 1000;
}

hr {
    border-style: dotted;
}

button {
  background-color: @brand-primary;
}
